import { makeStyles, Theme, createStyles } from "@material-ui/core";

import colors from "../../common/styles/colors";
import background from "../../assets/images/bg-mobile.png";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    containerPageLogin: {
      backgroundImage: `url(${background})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "right",
      width: "100%",
      height: "100vh",
      position: "fixed",
    },
    containerFormLogin: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "100vh",
    },
    logo: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    divider: {
      backgroundColor: colors.grayDLight,
      height: 2,
      marginBottom: "50px !important",
      marginTop: "50px !important",
    },
    titleWelcome: {
      color: colors.blackLight,
      marginBottom: "20px !important",
    },
    subtitleWelcome: {
      color: colors.blackLight,
      fontSize: "14px",
      marginBottom: "20px !important",
    },
    buttonMargin: {
      marginTop: "50px !important",
      marginBottom: "50px !important",
      backgroundColor: colors.purpleDarkest,
      borderColor: colors.purpleDarkest,
    },
    versionText: {
      color: colors.grayMedium,
      fontSize: "10px !important",
    },
  })
);
