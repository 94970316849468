import * as React from "react";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";

import { useStyles } from "./styles/index.styles";
import { useState, useEffect, createElement } from "react";
import LoginService from "../../../services/LoginService";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import secureLocalStorage from "react-secure-storage";
// import * as MuiIcons from "@material-ui/icons";
import { Icon, useMediaQuery, useTheme } from "@mui/material";

import logo_first from "../../../assets/images/logo_first.png";
import logo_second from "../../../assets/images/logo_second.png";

import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";
import HelpCenterOutlinedIcon from "@mui/icons-material/HelpCenterOutlined";

import menus from "./menu.json";

import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import colors from "../../styles/colors";
import ItemMenu, { SubmenuProps } from "./ItemMenu";
import { AppContext } from "../../../contexts/AppContext";
import { useMenu } from "../../hooks/useMenu";
import { MenuProps } from "../../dtos/menuDtos";
import { SubmenuItem } from "./ItemMenu/SubmenuItem";
import useLayoutStyles from "../../hooks/useLayoutStyles";
import AttachmentService from "../../../services/AttachmentService";
import AvatarHiplade from "../avatarhiplade";

interface MenuItem {
  title: string;
  link: string;
  submenu: MenuItem[] | false;
}

type Props = {
  showMenu: any;
  openMenu?: () => void;
};

export default function Menu({ showMenu, openMenu }: Props) {
  const { t } = useTranslation();
  const theme = useTheme();
  const isLG = useMediaQuery(theme.breakpoints.up("lg"));
  const isXS = useMediaQuery(theme.breakpoints.down("lg"));
  const nameUser = secureLocalStorage.getItem("name");
  const auth_user_id = secureLocalStorage.getItem("id");
  const avatar_url = secureLocalStorage.getItem("avatar_url");

  const localPermissions = secureLocalStorage
    .getItem("permissions")
    ?.toString();
  const permissions = localPermissions?.split(",");
  const role = secureLocalStorage.getItem("role");

  const navigate = useNavigate();

  const [attachment, setAttachment] = useState([] as any);
  const [url, setUrl] = useState("");
  const [activeSubmenu, setActiveSubmenu] = useState<string | null>(null); // Estado para controlar o submenu ativo

  const feature = "quickguide";
  const idGuide = 2; //id do perfil coordenador, porque o manual é o mesmo p/ adm
  const name = "manual_hiplade_coordinator"; // nome de campo do perfil coodenador, porque o manual é o mesmo p/ adm

  const getFile = async () => {
    await AttachmentService.get(feature, idGuide, name)
      .then((response) => {
        setAttachment(response.data);
        if (response.data.length > 0) {
          setUrl(response.data[0].url);
        }
      })
      .catch((e: any) => {});
  };

  useEffect(() => {
    if (idGuide) {
      getFile();
    }
  }, []);

  const {
    state: { menu },
    //updateDataContext,
  } = React.useContext(AppContext);

  const classes = useStyles(menu as MenuProps);

  const {
    handleOpenMenu,
    handleMenuActive: originalHandleMenuActive,
    onSubmenuPathname,
  } = useMenu();

  const { pathname } = useLocation();

  const { menuSideBarWidth, smallScreen } = useLayoutStyles();

  const handleMenuActive = (title: string, id: string, link?: string) => {
    setActiveSubmenu(title); // Define o submenu ativo
    originalHandleMenuActive(title, id, link);
  };

  const handleBackToMain = () => {
    setActiveSubmenu(null); // Reseta o submenu ativo
  };

  const logout = () => {
    LoginService.logout()
      .then((response: any) => {
        if (response.status == 200) {
          secureLocalStorage.removeItem("token");
          secureLocalStorage.removeItem("name");
          secureLocalStorage.removeItem("id");
          secureLocalStorage.removeItem("role");
          secureLocalStorage.removeItem("avatar_url");
          secureLocalStorage.removeItem("dt_start_session");
          secureLocalStorage.removeItem("permissions");
          secureLocalStorage.removeItem("expires_in");
          localStorage.removeItem("menu");
          navigate("/login");
        }
      })
      .catch((e: Error) => {
        toast(e.message, {
          type: "error",
        });
      });
  };

  useEffect(() => {
    onSubmenuPathname();
  }, [pathname]);

  return (
    <Box sx={{ width: menuSideBarWidth }} className={classes.containerMenu}>
      <Box
        sx={{ position: "block", bottom: 0 }}
        className={classes.containerMenuBottom}
      >
        {isXS && (
          <>
            <Box className={classes.boxAvatarMobile}>
              <Link href="/users/profile" sx={{ textDecoration: "none" }}>
                <AvatarHiplade
                  nameUser={nameUser}
                  size="small"
                  id={auth_user_id}
                  source={avatar_url}
                />
              </Link>
            </Box>
            <Link
              href={url}
              target="_blank"
              onClick={(event) => {
                event.preventDefault();
                if (url) {
                  window.open(url, "_blank");
                } else {
                  getFile();
                }
              }}
              className={classes.linkContent}
              sx={{
                textDecoration: "none",
                marginBottom: 1,
                paddingTop: 2,
                cursor: "pointer",
              }}
            >
              <HelpCenterOutlinedIcon
                sx={{ fontSize: 40, marginLeft: "-5px" }}
                className={classes.colorItemLogout}
              />{" "}
              {menu?.visibleMenu && (
                <Typography sx={{ color: colors.white, ml: 2 }}>
                  {t("menu_mobile_quick_guide")}
                </Typography>
              )}
            </Link>
          </>
        )}
      </Box>
      <CssBaseline />
      <Box sx={{ textAlign: "center" }} className={classes.containerLogo}>
        {!smallScreen && (
          <Link href="/home">
            {!menu?.visibleMenu ? (
              <img src={logo_first} style={{ height: "60px" }} />
            ) : (
              <img src={logo_second} style={{ height: "100px" }} />
            )}
          </Link>
        )}
        {!smallScreen && (
          <Box className={classes.buttonOpenMenu}>
            <Icon onClick={handleOpenMenu} sx={{ cursor: "pointer" }}>
              {menu?.visibleMenu
                ? "keyboard_double_arrow_left_outlined_icon"
                : "keyboard_double_arrow_right_outlined_icon"}
            </Icon>
          </Box>
        )}
        <Box className={classes.containerMenuItens}>
          <Box className={classes.containerSubMenu}>
            {menus
              .filter(
                (menu) =>
                  !(
                    ((t(menu.title) === "Configurações" ||
                      t(menu.title) === "Preparar eventos") &&
                      role == "5") ||
                    (t(menu.title) === "Notas e feedbacks" && role != "5")
                  )
              )
              .map((item, index) => (
                <ItemMenu
                  key={index}
                  onClick={() =>
                    handleMenuActive(t(item.title), item.id, item.link)
                  }
                  onClickArrowMenu={() =>
                    handleMenuActive(t(item.title), item.id)
                  }
                  iconName={item.icon}
                  title={t(item.title)}
                  isOpenMenu={menu?.menuActive === t(item.title)}
                  submenus={item?.submenu as any}
                  isHomeMenu={item.id === "1" || item.id === "2"}
                  profileId={role}
                  idMenu={item.id}
                />
              ))}
          </Box>
        </Box>
      </Box>

      <Box
        sx={{ position: "absolute", bottom: 0 }}
        className={classes.containerMenuBottom}
      >
        <Link
          onClick={logout}
          className={classes.linkContent}
          sx={{ textDecoration: "none", marginBottom: 5, cursor: "pointer" }}
        >
          <ExitToAppOutlinedIcon
            sx={{ fontSize: 40 }}
            className={classes.colorItemLogout}
          />{" "}
          {menu?.visibleMenu && (
            <Typography sx={{ color: colors.white, ml: 2 }}>
              {t("menu_logout")}
            </Typography>
          )}
        </Link>
      </Box>
    </Box>
  );
}
