import React, { useState, useEffect, useCallback } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Button from "@mui/material/Button";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import Divider from "@material-ui/core/Divider";
import {
  FormControl,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
} from "@mui/material";

import { useDefaultStyles } from "../../common/styles/default";

import Banner from "../../common/components/banner";
import banner from "../../assets/images/banners/event_scenario_banner.png";

import { useStyles } from "../styles/index.styles";
import { useScenarioStyles } from "../styles/scenarios.styles";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { MAXLENGTH } from "../constants";
import { useModal } from "../../common/hooks/useModal";

import ThemesService from "../../services/ThemesService";
import IThemeList from "../../types/Themes";
import ICompetenceList from "../../types/Competence";

import CompetenceService from "../../services/CompetenceService";
import PlaylistAddOutlinedIcon from "@mui/icons-material/PlaylistAddOutlined";
import AutoAwesomeOutlinedIcon from "@mui/icons-material/AutoAwesomeOutlined";

import Checkbox from "@mui/material/Checkbox";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function Form() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { handleOpenModal, dataModal } = useModal();

  const styles = useDefaultStyles();
  const classes = useStyles();
  const scenarioStyle = useScenarioStyles();

  const { id } = useParams();

  const handleCompetenceChange = (event: SelectChangeEvent) => {
    setCompetenceId(event.target.value);
  };

  const skillOptions = [
    "Habilidades Clínicas",
    "Procedimentos Práticos",
    "Investigação do Paciente",
    "Gestão de Pacientes",
    "Promoção da Saúde e Prevenção de Doenças",
    "Comunicação",
    "Tratamento de informações",
    "Compreensão das Ciências Básicas e Clínicas",
    "Atitudes e Ética",
    "Tomada de Decisão / Raciocínio Clínico",
    "Papel do médico",
    "Desenvolvimento pessoal",
  ];

  const [idScenario, setIdScenario] = useState(id);
  const [title, setTitle] = useState("");
  const [theme_id, setThemeId] = useState("");
  const [area_id, setAreaId] = useState("");
  const [subarea_id, setSubareaId] = useState("");
  const [specialty_id, setSpecialtyId] = useState("");
  const [dcn_id, setDcnId] = useState("");
  const [theme_specialty, setThemeSpecialty] = useState("");
  const [skill, setskill] = useState("");
  const [complexity, setComplexity] = useState("");
  const [environment, setEnvironment] = useState("");
  const [sex, setSex] = useState("");
  const [time_duration, setTimeDuration] = useState("");
  const [age, setAge] = useState("");
  const [goal_scene, setGoalScene] = useState("");
  const [description_scene, setDescriptionScene] = useState("");
  const [illness, setIllness] = useState("");
  const [allergy, setAllergy] = useState("");
  const [habits, setHabits] = useState("");
  const [surgeries, setSurgeries] = useState("");
  const [family_health_history, setFamilyHealthHistory] = useState("");
  const [continuous_use_medications, setContinuousUseMedications] =
    useState("");
  const [temporary_medications, setTemporaryMedications] = useState("");
  const [health_guidelines, setHealthGuidelines] = useState("");
  const [physical_exam, setPhysicalExam] = useState("");
  const [history_vital_sign, setHistoryVitalSign] = useState<any[]>([]);
  const [dataExam1, setDataExam1] = useState("");
  const [dataExam2, setDataExam2] = useState("");
  const [dataExam3, setDataExam3] = useState("");
  const [competences, setCompetences] = useState<ICompetenceList[]>([]);
  const [competence_id, setCompetenceId] = useState("");

  const [themes, setThemes] = useState<IThemeList[]>([]);

  const handleAreaChange = (event: SelectChangeEvent) => {
    setAreaId(event.target.value);
  };

  const addTheme = () => {
    handleOpenModal({
      displayName: "ThemeModal",
      data: { loadThemes: loadThemes, checklist: 1 },
    });
  };

  const addCompetence = () => {
    handleOpenModal({
      displayName: "CompetenceModal",
      data: { loadCompetences: loadCompetences, checklist: 1 },
    });
  };

  const handleThemeChange = (event: SelectChangeEvent) => {
    setThemeId(event.target.value);
  };

  const handleEnvironmentChange = (event: SelectChangeEvent) => {
    setEnvironment(event.target.value);
  };

  const goBack = () => {
    navigate("/genhi/list");
  };

  const handleComplexityChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setComplexity((event.target as HTMLInputElement).value);
  };

  const handleSexChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSex((event.target as HTMLInputElement).value);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setOpen(true);

    // if (title == "") {
    //   toast(t("toast_msg_formscenaro_title"), {
    //     type: "error",
    //   });
    //   return;
    // }

    // if (theme_id == "") {
    //   toast(t("toast_msg_formscenaro_theme"), {
    //     type: "error",
    //   });
    //   return;
    // }

    // if (competence_id == "") {
    //   toast(t("toast_msg_formscenaro_competence"), {
    //     type: "error",
    //   });
    //   return;
    // }

    // if (complexity == "") {
    //   toast(t("toast_msg_formscenaro_complexity"), {
    //     type: "error",
    //   });
    //   return;
    // }

    // if (environment == "") {
    //   toast(t("toast_msg_formscenaro_environment"), {
    //     type: "error",
    //   });
    //   return;
    // }

    // if (sex == "") {
    //   toast(t("toast_msg_formscenaro_gender"), {
    //     type: "error",
    //   });
    //   return;
    // }

    // if (time_duration == "") {
    //   toast(t("toast_msg_formscenaro_time_duration"), {
    //     type: "error",
    //   });
    //   return;
    // }

    // if (age == "") {
    //   toast(t("toast_msg_formscenaro_age"), {
    //     type: "error",
    //   });
    //   return;
    // }

    // if (goal_scene == "") {
    //   toast(t("toast_msg_formscenaro_goal_scene"), {
    //     type: "error",
    //   });
    //   return;
    // }

    // if (description_scene == "") {
    //   toast(t("toast_msg_formscenaro_description_scene"), {
    //     type: "error",
    //   });
    //   return;
    // }

    // const arrHistoryVital = formatArrHistoryVital();

    // const dataScenario = {
    //   name: title,
    //   theme_id: theme_id,
    //   complexity: complexity,
    //   environment: environment,
    //   sex: sex,
    //   time_duration: time_duration,
    //   age: age,
    //   goal_scene: goal_scene,
    //   description_scene: description_scene,
    //   illness: illness,
    //   allergy: allergy,
    //   habits: habits,
    //   surgeries: surgeries,
    //   family_health_history: family_health_history,
    //   continuous_use_medications: continuous_use_medications,
    //   temporary_medications: temporary_medications,
    //   health_guidelines: health_guidelines,
    //   history_vital_sign: arrHistoryVital,
    //   physical_exam: physical_exam,
    //   competence_id: competence_id,
    // };

    // if (!idScenario) {
    //   ScenarioService.store(dataScenario)
    //     .then((response: any) => {
    //       setIdScenario(response.data.id);
    //       loadMetrics();
    //       toast(response.data.Msg, {
    //         type: "success",
    //       });
    //     })
    //     .catch((e: any) => {
    //       toast(e?.response?.data?.Error, {
    //         type: "error",
    //       });
    //     });
    // } else {
    //   ScenarioService.update(idScenario, dataScenario)
    //     .then((response: any) => {
    //       loadMetrics();
    //       toast(response.data.Msg, {
    //         type: "success",
    //       });
    //     })
    //     .catch((e: any) => {
    //       toast(e?.response?.data?.Error, {
    //         type: "error",
    //       });
    //     });
    // }
  };

  const envir = [
    {
      value: 1,
      label: t("title_enviroment_outpatient"),
    },
    {
      value: 2,
      label: t("title_enviroment_emergency_room"),
    },
    {
      value: 3,
      label: t("title_enviroment_impatient_unit"),
    },
    {
      value: 4,
      label: t("title_enviroment_intensive_care_unit"),
    },
    {
      value: 5,
      label: t("title_enviroment_surgical_center"),
    },
    {
      value: 6,
      label: t("title_enviroment_public_space"),
    },
  ];

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (id: any) => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const loadThemes = async (page: number, paginate: number) => {
    await ThemesService.getAll(page, paginate)
      .then((response: any) => {
        if (response) {
          setThemes(response.data);
        }
      })
      .catch((e: Error) => {});
  };

  const loadCompetences = async (page: number, paginate: number) => {
    await CompetenceService.getAll(page, paginate)
      .then((response: any) => {
        if (response) {
          setCompetences(response.data);
        }
      })
      .catch((e: Error) => {});
  };

  useEffect(() => {
    loadThemes(1, 0);
    loadCompetences(1, 0);
  }, []);

  return (
    <Box>
      <Banner
        type="small"
        image={banner}
        title={
          <Box sx={{ display: "flex", alignItems: "center", gap: 5 }}>
            <AutoAwesomeOutlinedIcon sx={{ width: "3em", height: "3em" }} />
            <Typography variant="h3" sx={{ fontWeight: "bold" }}>
              IA Generativa - GenHi
            </Typography>
          </Box>
        }
      />

      <Box component="form" noValidate onSubmit={handleSubmit}>
        <Box className={styles.containerForm}>
          <Grid container spacing={2} sx={{ mb: 4 }}>
            <Grid item xs={12}>
              <Box className={scenarioStyle.topField}>
                <TextField
                  required
                  fullWidth
                  id="title"
                  name="title"
                  label="Nome do Conjunto"
                  variant="outlined"
                  value={title}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setTitle(event.target.value);
                  }}
                />
              </Box>
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ mt: 5 }}>
            <Grid item xs={2}>
              <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                Itens pré requisitos
              </Typography>
            </Grid>
          </Grid>
          <Box className={scenarioStyle.topBox}>
            <Divider className={scenarioStyle.divider} />
          </Box>

          <Grid container spacing={2} sx={{ mt: 5 }}>
            <Grid item xs={2}>
              <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                {t("lbl_form_theme")}
              </Typography>
            </Grid>
            <Grid item xs={10} sx={{ textAlign: "right" }}>
              <Button
                variant="contained"
                size="large"
                sx={{ ml: 2 }}
                onClick={addTheme}
                className={scenarioStyle.importButton}
              >
                {" "}
                {t("btn_label_create_theme")}
              </Button>
            </Grid>
          </Grid>
          <Box className={scenarioStyle.topBox}>
            <Divider className={scenarioStyle.divider} />
          </Box>
          <Typography sx={{ mb: 4, mt: 4 }}>
            <b>{t("text_checklist_theme_config_1")}</b> Todo Cenário e Checklist
            e deve estar atrelado a uma competência. Selelecione abaixo a
            Competência desejada ou crie uma nova.
          </Typography>

          <Grid container spacing={2} sx={{ mb: 8 }}>
            <Grid item xs={12}>
              <FormControl fullWidth sx={{ mt: 2 }}>
                <InputLabel id="theme-label">
                  {t("input_label_theme_ch")}
                </InputLabel>
                <Select
                  labelId="theme-label"
                  id="theme_id"
                  label={t("input_label_theme_ch")}
                  value={theme_id}
                  onChange={handleThemeChange}
                >
                  {themes.map((row) => (
                    <MenuItem value={row.id} key={row.id}>
                      {row.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={2}>
              <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                {t("lbl_form_competence")}*
              </Typography>
            </Grid>
            <Grid item xs={10} sx={{ textAlign: "right" }}>
              <Button
                variant="contained"
                size="large"
                sx={{ ml: 2 }}
                onClick={addCompetence}
                className={scenarioStyle.importButton}
              >
                {" "}
                {t("btn_label_create_competence")}
              </Button>
            </Grid>
          </Grid>
          <Box className={scenarioStyle.topBox}>
            <Divider className={scenarioStyle.divider} />
          </Box>
          <Typography sx={{ mb: 4, mt: 4 }}>
            <b>{t("text_checklist_theme_config_1")}</b> Todo Cenário e Checklist
            e deve estar atrelado a uma competência. Selelecione abaixo a
            Competência desejada ou crie uma nova.
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth sx={{ mt: 2 }}>
                <InputLabel id="theme-label">
                  {t("input_label_competencia_ch")}
                </InputLabel>
                <Select
                  labelId="theme-label"
                  id="competence_id"
                  label={t("input_label_competencia_ch")}
                  value={competence_id}
                  onChange={handleCompetenceChange}
                >
                  {competences.map((row) => (
                    <MenuItem value={row.id} key={row.id}>
                      {row.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ mt: 3 }}>
            <Grid item xs={12}>
              <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                Habilidades*
              </Typography>
            </Grid>
          </Grid>
          <Box className={scenarioStyle.topBox}>
            <Divider className={scenarioStyle.divider} />
          </Box>
          <Typography sx={{ mb: 4, mt: 4 }}>
            <b>{t("text_checklist_theme_config_1")}</b> Lorem Ipsum
          </Typography>
          <Grid container spacing={2} sx={{ mt: 3 }}>
            <Grid item xs={12}>
              <Autocomplete
                multiple
                id="checkboxes-tags-demo"
                options={skillOptions}
                disableCloseOnSelect
                getOptionLabel={(option) => option}
                renderOption={(props, option, { selected }) => {
                  const { ...optionProps } = props;
                  return (
                    <li {...optionProps}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option}
                    </li>
                  );
                }}
                style={{ width: 500 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Habilidades"
                    placeholder="Habilidades"
                    style={{
                      width: "100%",
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ mt: 3 }}>
            <Grid item xs={12}>
              <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                Configurações
              </Typography>
            </Grid>
          </Grid>
          <Box className={scenarioStyle.topBox}>
            <Divider className={scenarioStyle.divider} />
          </Box>
          <Grid container spacing={2} sx={{ mt: 3 }}>
            <Grid item xs={6}>
              <Grid container spacing={2}>
                <Grid item xs={5}>
                  {t("input_label_time_duration")}
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    required
                    fullWidth
                    id="time_duration"
                    name="time_duration"
                    variant="outlined"
                    value={time_duration}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setTimeDuration(event.target.value);
                    }}
                    type="time"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={3}>
                      {t("input_label_environment")}
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl fullWidth>
                        <Select
                          labelId="environment-label"
                          id="environment"
                          value={environment}
                          onChange={handleEnvironmentChange}
                        >
                          {envir.map((row, index) => (
                            <MenuItem value={row.value} key={index}>
                              {row.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>

        <Box className={scenarioStyle.boxScenarioRegister}>
          <Box className={styles.containerForm}>
            <Grid container spacing={2} sx={{ mt: 5 }}>
              <Grid item xs={12}>
                <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                  Objetivo
                </Typography>
              </Grid>
            </Grid>
            <Box className={scenarioStyle.topBox}>
              <Divider className={scenarioStyle.divider} />
            </Box>
            <Box className={scenarioStyle.topField}>
              <Typography sx={{ mb: 2 }}>
                Digite o objetivo do enredo de background
              </Typography>
              <TextField
                required
                fullWidth
                id="goal_scene"
                name="goal_scene"
                placeholder="Crie um contexto onde uma criança apresenta febre alta persistente, acompanhada de tosse e dificuldade para respirar. A mãe está preocupada e espera orientação sobre o tratamento adequado."
                variant="outlined"
                value={goal_scene}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setGoalScene(event.target.value);
                }}
                multiline
                rows={5}
              />
            </Box>
          </Box>
        </Box>

        <Box className={styles.containerButton}>
          <Button type="submit" variant="contained" size="large">
            <AutoAwesomeOutlinedIcon sx={{ mr: 1 }} />
            Gerar Background
          </Button>
          <Button
            color="secondary"
            type="submit"
            variant="contained"
            size="large"
            sx={{ ml: 3 }}
            onClick={goBack}
          >
            {" "}
            Voltar{" "}
          </Button>
        </Box>
      </Box>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Gerando background</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            O cenário e checklist serão criados conforme especificações. Retorne
            para o histórico de itens para acompanhar o status de criação, esse
            processo pode levar alguns minutos, após esse processo é necessário
            validar os itens que foram criados. Somente após a sua validação o
            cenário ou checklist estarão disponível para uso.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={goBack}>Retornar</Button>
          {/* <Button onClick={removeEntity} autoFocus>
            {t("btn_dialog_yes")}
          </Button> */}
        </DialogActions>
      </Dialog>
    </Box>
  );
}
